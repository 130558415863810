
.com-article-component {
    .com-title {
        border-bottom: none;
    }
    ul.com-article {
        margin-top: 30px; //17
        margin-bottom: 30px;
        display: flex;
        // display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 551px;
            box-sizing: border-box;
            line-height: 19px;
            padding-bottom: 12px;
            padding-top: 2px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                width: 431px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                font-size: 16px;
                color: #333;

            }
            span {
                width: 80px;
                font-size: 14px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p a, span a {
                width: 100%;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        li {
            padding-left: 10px;
            position: relative;
        }
        
        li::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
        

        li:nth-child(2n + 2) {
        }
        li:nth-child(2n + 1) {
            padding-left: 16px;
            position: relative;
        }
        li:nth-child(2n+1)::after {
            content: '';
            position: absolute;
            right: -22px;
            top: 0;
            width: 1px;
            height: 34px;
            background: #e5e5e5;
        }
        li:nth-child(2n + 1)::before {
            left: 6px;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
