
.com-book-component {
    .com-title {
        border-bottom: none;
    }
    ul.com-book {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 35px;
            margin-bottom: 52px;
            margin-top: 15px;
            position: relative;
            img {
                display: block;
                width: 200px;
                height: 270px;
                margin-bottom: 16px;
                cursor: pointer;
                box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.18);
                // box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
            }
            p {
                position: absolute;
                left: 50%;
                bottom: -5px;
                transform: translateX(-50%);
                width: 176px;
                line-height: 44px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                
                background: #FEE8DB;
                box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
                padding: 0 10px;
                text-align: center;
                a {
                    width: 156px;
                    display: block;
                    font-size: 14px;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        li:nth-child(5n) {
            margin-right: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
