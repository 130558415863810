
.subject-detail-page {
    .subject-detail {
        display: flex;
        justify-content: space-between;

        .left-subject-detail {
            width: 1200px;
            .swiper-wrap {
                height: 510px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                position: relative;
                img {
                    width: 1200px;
                    height: 510px;
                }
                .content-wrap {
                    // height: 112px;
                    width: 1200px;
                    position: absolute;
                    bottom: 21px;
                    left: 0;
                    // border: 1px solid #f5f5f5;
                    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                    // margin: 25px 0 25px;
                    box-sizing: border-box;
                    padding: 18px 23px;

                    background: #ffffff;
                    opacity: 0.89;
                    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

                    p {
                        font-size: 16px;
                        line-height: 26px;
                        // text-overflow: -o-ellipsis-lastline;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 3;
                        // line-clamp: 3;
                        // -webkit-box-orient: vertical;
                    }
                }
                // h3 {
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 1200px;
                //     height: 50px;
                //     background: rgba(2, 2, 2, 0.5);
                //     p {
                //         width: 836px;
                //         height: 50px;
                //         line-height: 50px;
                //         overflow: hidden;
                //         text-overflow: ellipsis;
                //         white-space: nowrap;
                //         color: #fff;
                //         font-size: 22px;
                //         margin-left: 22px;
                //     }
                // }
            }
            .sort-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px 40px;
                margin-bottom: 25px;
                margin-top: 28px;
                .com-title {
                    border-bottom: none;
                }
                .sort {
                    
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            width: 200px;
                            height: 70px;
                            margin-right: 35px;
                            background: rgba(254, 232, 219, 1);
                            position: relative;
                            line-height: 70px;
                            text-align: center;
                            color: #666;
                            font-size: 22px;
                            cursor: pointer;
                            margin-top: 30px;
                        }
                        li.active-sort {
                            color: #fff;
                        }
                        li.active-sort:after {
                            content: '';
                            position: absolute;
                            bottom: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 0;
                            border-left: 14px solid transparent;
                            border-right: 14px solid transparent;
                            border-top: 28px solid #bd1a2d;
                        }
                        li:nth-child(5n) {
                            margin-right: 0;
                        }
                        li.active-sort {
                            background: rgba(189, 26, 45, 1);
                        }
                    }
                }
            }

            .book-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px;
                margin-bottom: 21px;
            }
            .article-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px;
                margin-bottom: 21px;
            }
            .official-account-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 30px;
                margin-bottom: 35px;
                .com-title {
                    border-bottom: none;
                }
                ul.official-account {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 20px;
                    li {
                        width: 540px;
                        // border-bottom: 1px solid #e6e6e6;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        .img-wrap {
                            margin-right: 20px;
                            img {
                                width: 44px;
                                height: 44px;
                                cursor: pointer;
                            }

                        }
                        .content-fficial {
                            width: 477px;
                            p {
                                color: #333;
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 16px;
                                width: 100%;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                cursor: pointer;
                            }
                            div {
                                // display: flex;
                                // justify-content: space-between;
                                margin-top: 15px;
                                span {
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #999;
                                }
                                span:nth-child(1) {
                                    margin-right: 20px;
                                }
                            }
                        }
                     
                     
        
                    }
                    li:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .right-subject-detail {
            .hot-subject-wrap {
                width: 316px;
                padding: 10px 20px 0;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                ul.hot-subject {
                    li {
                        height: 54px;
                        line-height: 54px;
                        border-bottom: 1px solid #dedede;
                        cursor: pointer;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    li:last-child {
                        border: none;
                    }
                }
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin: 0px 0 10px;
    }
}
